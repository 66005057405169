import * as ENV from '../env';
import { io } from 'socket.io-client';
const socket = io(ENV.socketServer);

export default class OrderManager
{
    orders()
    {
        // Si des données nécessaires ne sont pas trouvées, rediriger sur le login
        if (localStorage.getItem('username') == null || localStorage.getItem('bearer') == null || localStorage.getItem('id_resto') == null) {
            let loginUser = localStorage.getItem('username');
            let rememberme = localStorage.getItem('rememberme');
            localStorage.clear();

            if (rememberme != null && rememberme) {
                localStorage.setItem('username', loginUser);
                localStorage.setItem('rememberme', rememberme);
            }
            window.location.pathname = '';
        }
        
        return {
            closedToday: false,
            closedServing: {
                lunch: false,
                evening: false
            },
            calendar: false,
            tracking: null,
            orders: null,
            ordersResa: null,

            isSubmitting: false,
            messageShow: false,
            messageToShow: {
                first: '',
                second: ''
            },

            username: localStorage.getItem('username'),
            restaurant: null,

            date: {
                hours: new Date().getHours().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }),
                minutes: new Date().getMinutes().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }),
                seconds: new Date().getSeconds().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }),
            },

            dateFormatted: '...',
            timeFormatted: '...',

            checkIsClosed() {
                this.closedToday = false;
                this.closedServing = {
                    lunch: false,
                    evening: false
                };
                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/closures/1', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        response.forEach(fermeture => {
                            let today = new Date();
                            let beginDate = new Date(fermeture.begin_date);
                            beginDate.setHours(0, 0, 1);
                            let endDate = new Date(fermeture.end_date);
                            endDate.setHours(23, 59, 59);
                            let lunch_serving = fermeture.lunch_serving;
                            let evening_serving = fermeture.evening_serving;
                            
                            if (beginDate.getTime() <= today.getTime() && endDate.getTime() >= today.getTime()) {
                                this.closedToday = true;
                                if (lunch_serving) {
                                    if (this.date.hours < 15) {
                                        this.closedServing.lunch = lunch_serving;
                                    } else {
                                        if (!evening_serving) {
                                            this.closedToday = false;
                                        }
                                    }
                                }
                                if (evening_serving) {
                                    this.closedServing.evening = evening_serving;
                                }
                            }
                        });
                    });
                });
            },

            changeCalendar() {
                this.calendar = !this.calendar;
                this.checkIsClosed();
            },

            getOrders(showmessage = false) {
                this.checkIsClosed();
                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/ordersnotcompleted', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        let allOrders = response;
                        let orders = [];
                        let ordersResa = [];
                        let currentDate = new Date().setHours(0,0,0,0);

                        allOrders.forEach(order => {
                            let orderDateArr = order.date.split('/', 3);
                            let orderDate = orderDateArr[2] + '-' + orderDateArr[1] + '-' + orderDateArr[0];
                            // console.log(order);
                            if (order.status.value != 'wc-completed') {
                                if (currentDate < new Date(orderDate).setHours(0,0,0,0)) {
                                    ordersResa.push(order);
                                } else if (currentDate == new Date(orderDate).setHours(0,0,0,0)){
                                    orders.push(order);
                                }
                            }                            
                        });

                        // orders.sort(function(a,b){
                        //     return parseInt(b.ID) - parseInt(a.ID);
                        // });

                        orders.sort(function(a,b){
                            let aOrderTimeArr = a.time.value.split('-', 2);
                            let aOrderTime = parseInt(aOrderTimeArr[1]);
                            let bOrderTimeArr = b.time.value.split('-', 2);
                            let bOrderTime = parseInt(bOrderTimeArr[1]);

                            return parseInt(aOrderTime) - parseInt(bOrderTime);
                        });

                        // orders.sort(function(a,b){
                        //     let timeAArr = ['0000'];
                        //     let timeBArr = ['0000'];

                        //     if (null != a.time && undefined != a.time.value) {
                        //         timeAArr = a.time.value.split('-', 2);
                        //     }

                        //     if (null != b.time && undefined != b.time.value) {
                        //         timeBArr = b.time.value.split('-', 2);
                        //     }

                        //     let timeA = timeAArr[0];
                        //     let timeB = timeBArr[0];

                        //     return parseInt(timeB) - parseInt(timeA);
                        // });
                        
                        ordersResa.sort(function(a,b){                            
                            let dateAArr = a.date.split('/', 3);
                            let dateBArr = b.date.split('/', 3);

                            let dateA = dateAArr[2] + '-' + dateAArr[1] + '-' + dateAArr[0];
                            let dateB = dateBArr[2] + '-' + dateBArr[1] + '-' + dateBArr[0];

                            return new Date(dateB).setHours(0,0,0,0) - new Date(dateA).setHours(0,0,0,0);
                        });

                        this.orders = orders;
                        this.ordersResa = ordersResa;

                        // console.log(this.orders);

                        if (showmessage) {
                            var audioElement = document.getElementById('xyz');
                            audioElement.play();
                            this.messageShow = true;
                            this.messageToShow.first = 'Nouvelle commande !';
                            this.messageToShow.second = 'Une nouvelle commande a été déposée.';
                        }
                    });
                });
            },

            getTracking() {
                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/tracking', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        
                        if (undefined != response.data && Array.isArray(response.data) && undefined != response.data[0]) {
                            this.tracking = response.data[0];
                        }
                    });
                });
            },

            initOrders() {
                this.isSubmitting = true;
                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto'), {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    // console.log(response);
                    if (response.status == 403) {
                        let loginUser = localStorage.getItem('username');
                        let rememberme = localStorage.getItem('rememberme');
                        localStorage.clear();
            
                        if (rememberme != null && rememberme) {
                            localStorage.setItem('username', loginUser);
                            localStorage.setItem('rememberme', rememberme);
                        }
                        window.location.pathname = '';
                    }
                    return response.json().then((response) => {
                        if (response.ID) {
                            if (parseInt(response.ID) === parseInt(localStorage.getItem('id_resto'))) {
                                localStorage.setItem('restaurant', JSON.stringify(response));
                                this.restaurant = JSON.parse(localStorage.getItem('restaurant'));
                                // console.log(this.restaurant);
                                this.isSubmitting = false;
                                this.getOrders();
                                this.getTracking();

                                const orderManager = this;

                                socket.on('updateOrder', function(data) {
                                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                                        orderManager.getOrders();
                                    }
                                });

                                socket.on('newOrder', function(data) {
                                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                                        orderManager.getOrders(true);
                                    }
                                });

                                socket.on('updateWithdrawal', function(data) {
                                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                                        orderManager.restaurant.fields.shop_withdrawal_time = data.value;
                                    }
                                });

                                socket.on('updateDelivery', function(data) {
                                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                                        orderManager.restaurant.fields.shop_delivery_time = data.value;
                                    }
                                });
                            }
                        }
                    })
                });
            },

            getDate() {
                const date = new Date();
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                this.date.hours = date.getHours().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });
                this.date.minutes = date.getMinutes().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });
                this.date.seconds = date.getSeconds().toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });

                if (this.date.seconds % 59 == 0) {
                    this.checkIsClosed();
                }

                this.dateFormatted = date.toLocaleDateString('fr-FR', options);
                // Capitalize première lettre de chaque mot
                this.dateFormatted = this.dateFormatted.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

                this.timeFormatted = this.date.hours + ':' + this.date.minutes + ':' + this.date.seconds;
            },

            editDeliveryTime() {
                this.isSubmitting = true;

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/delivery', {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    },
                    body: JSON.stringify({
                        time: this.restaurant.fields.shop_delivery_time
                    })
                })
                .then((response) => {
                    this.isSubmitting = false;
                    this.messageShow = true;
                    
                    if (response.ok) {
                        this.messageToShow.first = 'Succès !';
                        this.messageToShow.second = 'Les paramètres ont été modifiés avec succès.';

                        socket.emit('updateDelivery', {
                            shopId: parseInt(localStorage.getItem('id_resto')),
                            value: this.restaurant.fields.shop_delivery_time
                        });
                    } else {
                        this.messageToShow.first = 'Erreur';
                        this.messageToShow.second = 'La valeur choisie est invalide.';
                    }

                    localStorage.setItem('restaurant', JSON.stringify(this.restaurant));
                });

            },

            editWithdrawalTime() {
                this.isSubmitting = true;

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/withdrawal', {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    },
                    body: JSON.stringify({
                        time: this.restaurant.fields.shop_withdrawal_time
                    })
                })
                .then((response) => {    
                    this.isSubmitting = false;
                    this.messageShow = true;
                    
                    if (response.ok) {
                        this.messageToShow.first = 'Succès !';
                        this.messageToShow.second = 'Les paramètres ont été modifiés avec succès.';

                        socket.emit('updateWithdrawal', {
                            shopId: parseInt(localStorage.getItem('id_resto')),
                            value: this.restaurant.fields.shop_withdrawal_time
                        });
                    } else {
                        this.messageToShow.first = 'Erreur';
                        this.messageToShow.second = 'La valeur choisie est invalide.';
                    }

                    localStorage.setItem('restaurant', JSON.stringify(this.restaurant));
                });

            },

            updateOrder(idOrder = null, status = null) {
                if (idOrder == null || status == null) {
                    return;
                }
                this.isSubmitting = true;

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/order/' + idOrder, {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    },
                    body: JSON.stringify({
                        status: status
                    })
                })
                .then((response) => {

                    return response.json().then((response) => {
                        this.isSubmitting = false;
                        this.messageShow = true;
                        
                        if (response.success) {
                            this.orders.forEach((order, key) => {
                                if (order.ID == idOrder) {
                                    if (status == 'wc-completed') {
                                        this.orders.splice(key, 1);
                                    } else {
                                        order.status.label = response.status_label;
                                        order.status.value = status;
                                    }
                                }
                            });
                            this.messageToShow.first = 'Succès !';
                            this.messageToShow.second = 'Le statut de la commande a été modifié.';

                            socket.emit('updateOrder', {
                                shopId: parseInt(localStorage.getItem('id_resto'))
                            });
                        } else {
                            this.messageToShow.first = 'Erreur';
                            this.messageToShow.second = response.error;
                        }
                    });
                });

            },

            outputOrder(idOrder = null) {
                if (idOrder == null) {
                    return;
                }
                this.isSubmitting = true;

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/order/' + idOrder + '/output', {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {

                    return response.json().then((response) => {
                        this.isSubmitting = false;
                        this.messageShow = true;
                        
                        if (response.ok) {
                            this.messageToShow.first = 'Succès !';
                            this.messageToShow.second = 'La commande a été renvoyée en caisse.';
                        } else {
                            this.messageToShow.first = 'Erreur';
                            this.messageToShow.second = "La commande n'a pas pu être renvoyée en caisse.";
                        }
                    });
                })
                .catch((error) => {
                    this.isSubmitting = false;
                    this.messageShow = true;
                
                    this.messageToShow.first = 'Erreur';
                    this.messageToShow.second = "La commande n'a pas pu être renvoyée en caisse.";
                });

            },

            logout() {
                let loginUser = localStorage.getItem('username');
                let rememberme = localStorage.getItem('rememberme');
                localStorage.clear();

                if (rememberme != null && rememberme) {
                    localStorage.setItem('username', loginUser);
                    localStorage.setItem('rememberme', rememberme);
                }
                window.location.pathname = '';
            }
        }
    }
}