import * as ENV from '../env';
import { io } from 'socket.io-client';
const socket = io(ENV.socketServer);

const MONTH_NAMES = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
const DAYS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const TODAY = (new Date()).toLocaleDateString('en-GB').split('/').reverse().join('-');
export default class CalendarManager
{

    calendar() {
        return {
            month: '',
            year: '',
            no_of_days: [],
            blankdays: [],
            days: DAYS,
            DAYS: DAYS,
            MONTH_NAMES: MONTH_NAMES,

            fermetures: [

            ],
            fermeture_reason: 1,
            fermeture_start: TODAY,
            fermeture_end: TODAY,
            fermeture_lunch: true,
            fermeture_evening: true,

            reasons: [],

            highlightedClosure: null,

            openEventModal: false,

            initDate() {
                let today = new Date();
                this.month = today.getMonth();
                this.year = today.getFullYear();
                this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString();

                const calendarManager = this;

                socket.on('updateFermetures', function(data) {
                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                        calendarManager.getFermetures();
                    }
                });
            },

            isToday(date) {
                const today = new Date();
                const d = new Date(this.year, this.month, date);

                return today.toDateString() === d.toDateString() ? true : false;
            },

            showEventModal() {
                this.openEventModal = true;
            },

            getNoOfDays() {
                if (this.month > 11) {
                    this.month = 0;
                    this.year++;
                } else if (this.month < 0) {
                    this.month = 11;
                    this.year--;
                }
                let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

                // find where to start calendar day of week
                let dayOfWeek = new Date(this.year, this.month).getDay();
                let blankdaysArray = [];
                for ( var i=1; i <= dayOfWeek; i++) {
                    blankdaysArray.push(i);
                }

                let daysArray = [];
                for ( var i=1; i <= daysInMonth; i++) {
                    daysArray.push(i);
                }
                
                this.blankdays = blankdaysArray;
                this.no_of_days = daysArray;
            },

            getFermetures() {
                fetch(ENV.url + 'wp-json/ladysushi/closure-reason', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        this.reasons = response;
                    });
                });

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/closures/1', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        let fermetures = [];

                        response.forEach(fermeture => {
                            let fermetureStartDateArr = fermeture.begin_date.split('-', 3);

                            let fermetureStartDate = fermetureStartDateArr[2] + '/' + fermetureStartDateArr[1] + '/' + fermetureStartDateArr[0];

                            let fermetureEndDateArr = [];

                            // Ne sera plus utile, a permi de servir de garde fou dans le cas d'une fermeture mal renseignée
                            if (fermeture.end_date == null) {
                                fermetureEndDateArr = fermetureStartDateArr;
                            } else {
                                fermetureEndDateArr = fermeture.end_date.split('-', 3);
                            }

                            const referenceDate = new Date();

                            // console.table([
                            //     parseInt(fermetureStartDateArr[0]),
                            //     parseInt(fermetureStartDateArr[1]),
                            //     parseInt(fermetureStartDateArr[2]),
                            //     parseInt(referenceDate.getFullYear()),
                            //     parseInt(referenceDate.getMonth() + 1),
                            //     parseInt(referenceDate.getDate())
                            // ]);

                            if (parseInt(fermetureEndDateArr[0]) < parseInt(referenceDate.getFullYear())) {
                                return;
                            }

                            if (parseInt(fermetureEndDateArr[1]) < parseInt(referenceDate.getMonth()) + 1 && parseInt(fermetureEndDateArr[0]) == parseInt(referenceDate.getFullYear())) {
                                return;
                            }

                            if (parseInt(fermetureStartDateArr[2]) < parseInt(referenceDate.getDate()) && parseInt(fermetureEndDateArr[1]) == parseInt(referenceDate.getMonth()) && parseInt(fermetureEndDateArr[0]) == parseInt(referenceDate.getFullYear())) {
                                return;
                            }

                            
                            let fermetureEndDate = fermetureEndDateArr[2] + '/' + fermetureEndDateArr[1] + '/' + fermetureEndDateArr[0];

                            let beginDate = new Date(fermeture.begin_date);
                            beginDate.setDate(beginDate.getDate() - 1);

                            fermeture.true_begin_date = beginDate;
                            fermeture.true_end_date = new Date(fermeture.end_date);
                            fermeture.begin_date = fermetureStartDate;
                            fermeture.end_date = fermetureEndDate;

                            fermetures.push(fermeture);                        
                        });

                        this.fermetures = fermetures;
                    });
                });
            },

            highlightFermeture(id_closure) {
                this.highlightedClosure = id_closure;
            },

            deleteFermeture(id_closure) {
                this.isSubmitting = true;

                fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/closure/' + id_closure, {
                    method: 'DELETE',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {    
                    this.isSubmitting = false;
                    this.messageShow = true;
                    
                    if (response.ok) {
                        this.fermetures.forEach((fermeture, key) => {
                            if (fermeture.id_closure == id_closure) {
                                this.fermetures.splice(key, 1);
                            }
                        });
                        this.messageToShow.first = 'Succès !';
                        this.messageToShow.second = 'La fermeture a été supprimée !';

                        socket.emit('updateFermetures', {
                            shopId: parseInt(localStorage.getItem('id_resto'))
                        });
                    } else {
                        this.messageToShow.first = 'Erreur';
                        this.messageToShow.second = 'Fermeture invalide.';
                    }
                });
            },

            addFermeture() {
                this.openEventModal = false;

                if (this.fermeture_reason == 4) {
                    this.fermeture_start = TODAY;
                    this.fermeture_end = TODAY;
                    this.fermeture_lunch = true;
                    this.fermeture_evening = true;
                }

                if (this.fermeture_start == null || this.fermeture_end == null) {
                    this.messageShow = true;
                    this.messageToShow.first = 'Erreur';
                    this.messageToShow.second = 'Vous devez renseigner une date de début et de fin pour la fermeture.';
                } else if (this.fermeture_start > this.fermeture_end) {
                    this.messageShow = true;
                    this.messageToShow.first = 'Erreur';
                    this.messageToShow.second = 'La date de fin doit être ultérieure à la date de début.';
                } else {
                    fetch(ENV.url + 'wp-json/ladysushi/restaurant/' + localStorage.getItem('id_resto') + '/closure', {
                        method: 'POST',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                        },
                        body: JSON.stringify({
                            reason: this.fermeture_reason, 
                            begin_date: this.fermeture_start, 
                            end_date: this.fermeture_end, 
                            lunch_serving: this.fermeture_lunch, 
                            evening_serving: this.fermeture_evening,
                        })
                    })
                    .then((response) => {
                        this.messageShow = true;
                        
                        if (response.ok) {
                            this.messageToShow.first = 'Succès !';
                            this.messageToShow.second = 'La fermeture a été ajoutée.';
    
                            this.getFermetures();
                            
                            socket.emit('updateFermetures', {
                                shopId: parseInt(localStorage.getItem('id_resto'))
                            });
                        } else {
                            this.messageToShow.first = 'Erreur';
                            this.messageToShow.second = 'Les valeurs choisies sont invalides.';
                        }
    
                        this.fermeture_reason = 1
                        this.fermeture_start = TODAY;
                        this.fermeture_end = TODAY;
                        this.fermeture_lunch = true
                        this.fermeture_evening = true
                    });
                }
            },
        }
    }
}