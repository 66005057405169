export let url = 'https://ladysushi-preprod.lajungle.biz/';

export let socketServer = 'https://ladysushi-pusher-pp.lajungle.biz/';

// https://ls2021.lan/
// https://ladysushi-preprod.lajungle.biz/
// https://new.ladysushi-preprod.lajungle.biz/
// https://lady-sushi.fr/


// https://ladysushi-pusher-pp.lajungle.biz/
// https://ladysushi-pusher.lajungle.biz/